import React from "react";
import Frame from "../../assets/frame5.gif";

const ProductInfo = ({
  product,
  quantityToOrder,
  handleQtyValue,
  handleSizeChange,
  chosenSizes,
  sizes,
  hideAddBtn,
  handleAddProduct,
}) => {
  return (
    <div className="col-md-4 text-start">
      {product?.quantityInStore === 0 && (
        <span className=" d-block w-50 p-3 mb-4 bg-danger text-white">
          Sold Out
        </span>
      )}

      <div className="d-flex gap-3">
        <h3
          className={`mt-3 mt-sm-0 ${
            product?.sale !== "null" &&
            "text-decoration-line-through text-muted"
          }`}
        >
          ${product?.price}
        </h3>

        {product?.sale !== "null" && (
          <h3 className={`mt-3 mt-sm-0`}>{`$${
            product?.price - (product?.price * product?.sale) / 100
          }   `}</h3>
        )}
      </div>

      <div className="d-flex gap-4 align-items-center my-4">
        <span>Quantity</span>
        <input
          className="w-100 py-2 px-2 border-0 focus-ring"
          type="number"
          min={1}
          max={+product?.quantityInStore || 10}
          defaultValue={quantityToOrder}
          readOnly={product?.quantityInStore === 0 ? true : false}
          onChange={handleQtyValue}
        />
      </div>
      {product.quantityInStore !== null && (
        <p className="text-danger fw-semibold">
          Only left {product?.quantityInStore} In Store
        </p>
      )}

      {product?.size !== "undefined" && product?.size?.length > 0 && (
        <select
          // multiple
          className="form-select mb-3"
          aria-label="Default select example"
          value={chosenSizes}
          onChange={handleSizeChange}
        >
          {sizes?.map((size) => {
            return (
              <option key={size} value={size}>
                {size}
              </option>
            );
          })}
        </select>
      )}

      <div className="d-flex">
        <div>
          <button
            onClick={handleAddProduct}
            className={`mt-5 p-4  border-1 mb-5 mb-sm-0 text-nowrap ${
              product?.quantityInStore === 0 || hideAddBtn
                ? "btn disabled opacity-50"
                : ""
            } `}
          >
            {product.quantityInStore === 0
              ? "Sold Out"
              : hideAddBtn
              ? "Thanks For Buying"
              : "Buy it Now"}
          </button>
        </div>

        <img
          alt="lion"
          className="lion-frame"
          style={{ transform: "scaleX(-1)", objectFit: "contain" }}
          title="per"
          src={Frame}
          width="250"
          height="250"
          frameBorder="0"
          allowFullScreen
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default ProductInfo;
