export const categoriesData = [
  {
    id: 1,
    text: "Secret biggie",
    // qty: "(4 Jackets)",
  },
  {
    id: 2,
    text: "Timepieces",
    // qty: "(2 Watches)",
  },
  {
    id: 3,
    text: "Pantaloons",
    // qty: "(FW 24)",
  },
  {
    id: 4,
    text: "Head head",
    // qty: "(SUMMER 24)",
  },
  {
    id: 5,
    text: "Teeeeees",
    // qty: "(SUMMER 24)",
  },
  {
    id: 6,
    text: "HOODfees",
    // qty: "(FW 24)",
  },
];
