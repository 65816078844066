import Navbar from "../components/navbar/Navbar";

const Cancel = () => {
  return (
    <>
      <Navbar />
      <div>
        <h2 className="text-danger fs-2">
          Your payment was canceled, pleasy try again.
        </h2>
      </div>
    </>
  );
};

export default Cancel;
