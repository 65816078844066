import React, { useState } from "react";
import Navbar from "../components/navbar/Navbar";
import { Link } from "react-router-dom";
import DeleteIcon from "../assets/icons8-delete.svg";
import Frame from "../assets/frame6.gif";
import Visa from "../assets/visa.jpg";
import Egypt from "../assets/egypt.png";
import axios from "axios";
import { BASE_URL } from "../data/BaseUrl";
import { toast } from "react-toastify";

const Cart = () => {
  let cartData = JSON.parse(localStorage.getItem("cart"));
  const [paymentUrl, setPaymentUrl] = useState({});

  let total = cartData?.reduce((acc, current) => {
    return acc + +current?.price * +current?.quantityToOrder;
  }, 0);

  let [quantityToOrder, setQuantityToOrder] = useState(0);
  const handleQtyChange = (e) => {
    setQuantityToOrder(e.target.value);
  };

  const handleDeleteItem = (id) => {
    cartData = cartData?.filter((item) => item.id !== id);
    localStorage.setItem("cart", JSON.stringify(cartData));
    window.reload();
  };

  const handlePayment = () => {
    const dataToSend = {
      products:
        cartData?.length > 0 &&
        cartData?.map((item) => {
          return {
            price: Math.ceil(item?.price),
            name: item?.name,
            description: item?.description,
            quantity: item?.quantityToOrder,
          };
        }),
    };

    axios.post(`${BASE_URL}api/Products/Payment`, dataToSend).then((res) => {
      if (res?.status === 200) {
        setPaymentUrl(res?.data?.paymentUrl);
        window.open(res?.data?.paymentUrl, "_blank");
      } else {
        toast.error("Invalid process, pleasy try again");
      }
    });
  };

  return (
    <div className="container">
      <Navbar />
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="text-start">Your Cart</h2>
        {/* <img src={Child} alt="child" /> */}
        <img
          src={Frame}
          alt=""
          width="120"
          height="120"
          frameBorder="0"
          title="cake"
          allowFullScreen
          style={{ objectFit: "contain" }}
          loading="lazy"
        />
      </div>

      <div style={{ overflowX: "auto" }}>
        <table className="table table-bordered table-hover mt-5">
          <thead>
            <tr className="text-center">
              <th colSpan={2}>Product</th>
              <th>Price</th>
              <th>Size</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {/* check Returned Cart items */}
            {cartData?.length > 0 ? (
              cartData?.map((item) => {
                quantityToOrder = item?.quantityToOrder;
                return (
                  <tr key={item?.id}>
                    <td className="text-center">
                      <Link
                        className="text-black"
                        onClick={() => handleDeleteItem(item.id)}
                      >
                        <img src={DeleteIcon} alt="delete" loading="lazy" />
                      </Link>
                    </td>
                    <td>
                      <Link to={`/products/${item?.id}`}>{item?.name}</Link>
                    </td>
                    <td className="text-center">${item?.price}</td>
                    <td>{item?.size}</td>
                    <td className="text-center">
                      <input
                        type="number"
                        min={0}
                        defaultValue={quantityToOrder}
                        readOnly
                        onChange={(e) => handleQtyChange(e, item?.id)}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="text-center " colSpan={5}>
                  no items found
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr className="text-start">
              <td className="bg-light fw-bold ps-md-5" colSpan={4}>
                SUBTOTAL
              </td>
              <td className="bg-light text-danger fw-bold" colSpan={1}>
                {cartData?.length === 0
                  ? 0
                  : `$${total === undefined ? 0 : total?.toFixed(2)}`}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <p className="text-danger">
        note: if you want to delete the same item with different sizes it will
        clear all item sizes for security issues.
      </p>
      <p>Shipping & taxes calculated at checkout</p>
      <div className="d-flex gap-3 justify-content-center mb-4 flex-column flex-sm-row">
        <Link
          className="bg-secondary py-2 px-3 text-decoration-none text-white"
          to="/"
        >
          Continue Shopping
        </Link>
        <Link
          className="bg-secondary py-2 px-3 text-decoration-none text-white"
          to="/"
        >
          Update
        </Link>
        {cartData?.length > 0 && (
          <Link
            className="bg-secondary py-2 px-3 text-decoration-none text-white"
            onClick={handlePayment}
          >
            Checkout
          </Link>
        )}
      </div>
      <img
        src={Visa}
        alt="visa"
        className="mb-5 img-fluid"
        style={{ width: "480px" }}
        loading="lazy"
      />
      <img
        src={Egypt}
        alt="egypt"
        className="d-block m-auto mw-100"
        loading="lazy"
      />
    </div>
  );
};

export default Cart;
