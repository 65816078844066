import React, { useContext, useEffect, useState } from "react";
import Navbar from "../components/navbar/Navbar";
import ProductGallery from "../components/ProductGallery/ProductGallery";
import ProductInfo from "../components/ProductInfo/ProductInfo";
import Welcome from "../components/welcome/Welcome";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CartContext, CartDispatchContext } from "../data/CartProvider";
import { BASE_URL } from "../data/BaseUrl";
import axios from "axios";
import Egypt from "../assets/egypt.png";

const ProductDetails = () => {
  const { id } = useParams();

  const [product, setProduct] = useState({});
  const [sizes, setSizes] = useState([]);
  const [chosenSizes, setChosenSizes] = useState("lg");
  const [showPayBtn, setShowBtn] = useState(false);
  const [hideAddBtn, setHideAddBtn] = useState(false);
  const [quantityToOrder, setQuantityToOrder] = useState(1);

  useEffect(() => {
    axios
      .get(`${BASE_URL}api/Products/GetProductById/?id=${id}`)
      .then((res) => {
        setProduct(res?.data?.data);
        setSizes(res?.data?.data?.size?.split(","));
      });
  }, [id]);

  useEffect(() => {
    if (savedData == [null]) {
      localStorage.setItem("cart", []);
    }
  });

  let cartData = useContext(CartContext);
  const setCartData = useContext(CartDispatchContext);
  let savedData = JSON.parse(localStorage.getItem("cart"));

  const handleQtyValue = (e) => {
    setQuantityToOrder(+e.target.value);
  };

  function checkExistance() {
    let existItem = cartData?.find(
      (item) => item.id === product.id && item.size === chosenSizes
    );
    if (existItem) {
      if (+product?.sale > 0) {
        existItem.quantityToOrder += +quantityToOrder;
        existItem.price = product.price - (product.price * product.sale) / 100;
      } else {
        existItem.quantityToOrder += +quantityToOrder;
      }

      localStorage.setItem("cart", JSON.stringify(cartData));
    } else {
      let newCartItem;
      if (+product?.sale > 0) {
        newCartItem = {
          ...product,
          quantityToOrder: +quantityToOrder,
          price: product.price - (product.price * product.sale) / 100,
          size: chosenSizes,
        };
      } else {
        newCartItem = {
          ...product,
          quantityToOrder: +quantityToOrder,
          size: chosenSizes,
        };
      }
      let newData = [...cartData, newCartItem];
      setCartData(newData);
      localStorage.setItem("cart", JSON.stringify(newData));
    }
  }

  const handleAddProduct = () => {
    toast.success(" Congrats, you just won this auction");
    setHideAddBtn(true);
    setShowBtn(true);

    checkExistance();
  };

  const handleSizeChange = (e) => {
    setChosenSizes(e.target.value);
  };

  return (
    <div className="container mb-5">
      <Navbar />

      <h2 className="text-start mt-4 mb-4 mb-md-0">{product?.name}</h2>

      {showPayBtn && (
        <div className="mb-5">
          <Link
            to="/cart"
            className="bg-secondary py-3 px-5  text-decoration-none text-white"
          >
            Pay Now !!!!
          </Link>
        </div>
      )}

      <div className="row my-3 w-100 text-start">
        <div className="col-md-5">
          <ProductGallery productImgs={product?.photo} />
        </div>

        <ProductInfo
          product={product}
          quantityToOrder={quantityToOrder}
          handleQtyValue={handleQtyValue}
          chosenSizes={chosenSizes}
          handleSizeChange={handleSizeChange}
          sizes={sizes}
          hideAddBtn={hideAddBtn}
          handleAddProduct={handleAddProduct}
        />

        <div className="col-md-3">
          <Welcome />
        </div>
      </div>

      <img
        src={Egypt}
        alt="egypt"
        className="d-block m-auto"
        width={450}
        height={200}
        loading="lazy"
        style={{ maxWidth: "100%", objectFit: "contain" }}
      />
    </div>
  );
};

export default ProductDetails;
