import React from "react";
import ReactImageGallery from "react-image-gallery";
import { BASE_URL } from "../../data/BaseUrl";

const ProductGallery = ({ productImgs }) => {
  const images = productImgs?.map((img) => {
    return {
      original: `${BASE_URL}${img?.filePath}`,
      thumbnail: `${BASE_URL}${img?.filePath}`,
    };
  });

  return (
    <div>
      {productImgs?.length > 0 && (
        <ReactImageGallery
          items={images}
          showPlayButton={false}
          showNav={false}
        />
      )}
    </div>
  );
};

export default ProductGallery;
