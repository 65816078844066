import React, { useEffect } from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Products from "../components/products/Products";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Logo from "../assets/logo.png";
import jwt_decode from "jwt-decode";

const Dashboard = (props) => {
  const [showDash, setShowDash] = useState(false);
  const [password, setPassword] = useState("");
  const [closedImg, setClosedImg] = useState(null);
  const navigate = useNavigate();

  const checkIsClosed = localStorage.getItem("closed");

  const secretKey = process.env.REACT_APP_NOP_KEY;
  const decoded = jwt_decode(secretKey);

  useEffect(() => {
    if (localStorage.getItem("LOGS_NO-REF_ORSEW")) {
      setShowDash(true);
    }
  }, []);

  const confirmIdentity = (e) => {
    e.preventDefault();
    if (password === decoded?.log) {
      toast.success("Hello Jessar");
      setShowDash(true);
      localStorage.setItem("LOGS_NO-REF_ORSEW", secretKey);
    } else {
      toast.error("please enter correct password");
      setPassword("");
    }
  };

  const handleSignOut = () => {
    localStorage.removeItem("LOGS_NO-REF_ORSEW");
    window.location.reload();
  };

  return (
    <div className="p-4">
      {!showDash ? (
        <form
          className="row g-3 align-items-center vh-100 flex-column"
          onSubmit={confirmIdentity}
        >
          <div className="col-auto">
            <label htmlFor="inputPassword2" className="visually-hidden">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="inputPassword2"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="col-auto">
            <button
              type="submit"
              className="btn btn-primary mb-3"
              onClick={confirmIdentity}
            >
              Confirm identity
            </button>
          </div>
          <ToastContainer></ToastContainer>
        </form>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center position-sticky top-0 bg-white px-3 z-3">
            <div className="d-flex align-items-center gap-3">
              <Link to={"/"}>
                <img
                  src={Logo}
                  alt="logo"
                  style={{ width: "120px", objectFit: "contain" }}
                  loading="lazy"
                />
              </Link>
              <h2>Hello, Jessar</h2>
            </div>
            <button className="btn btn-danger" onClick={handleSignOut}>
              Sign Out
            </button>
          </div>
          <div className="container">
            <div className="row my-4 w-75 m-auto">
              <div className="btn-group my-4 gap-1" role="group">
                <button
                  className="btn btn-primary"
                  onClick={() => navigate("/edit")}
                >
                  Edit Products
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => navigate("/add")}
                >
                  Add Product
                </button>

                {/* CLOSE WEBSITE */}

                {/* <button
                  className="btn btn-danger"
                  onClick={() => props.closeSite()}
                >
                  {checkIsClosed === "true" ? "Open website" : "Close website"}
                </button> */}
              </div>
              {/* {checkIsClosed === "true" && (
                <div className="d-block my-3">
                  <input
                    className="form-control form-control-lg"
                    id="formFileLg"
                    type="file"
                    accept="image/*"
                    onChange={handleCloseImg}
                  />
                  <img
                    style={{
                      width: "250px",
                      marginTop: "20px",
                      objectFit: "cover",
                    }}
                    src={closedImg && URL?.createObjectURL(closedImg)}
                    alt="closed"
                  />
                </div>
              )} */}
              <Products />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
