import React from "react";
import Navbar from "../components/navbar/Navbar";
import Main from "../components/main/Main";

const Home = () => {
  return (
    <div className="home">
      <div className="container">
        <Navbar />
        <Main />
      </div>
    </div>
  );
};

export default Home;
