import React from "react";
import "./closed.css";

const ClosedPage = () => {
  return (
    <div className="closed-page">
      <img src={localStorage.getItem("img")} alt="close-img" loading="lazy" />
    </div>
  );
};

export default ClosedPage;
