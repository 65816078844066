import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo.png";
import "./Navbar.css";

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <nav className="navbar">
      <div className="row w-100">
        <div className="col-md-3">
          <div className="logo">
            <Link to="/">
              <img
                className="img-fluid"
                src={Logo}
                alt="logo"
                width={207}
                height={200}
                loading="lazy"
              />
            </Link>
          </div>
        </div>
        <div className="col-md-9">
          <div className="d-flex flex-column align-items-center">
            <div>
              <ul className="list-unstyled d-flex gap-3">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/cart">Cart</Link>
                </li>
              </ul>
            </div>
            <div className="d-flex navBtns justify-content-center">
              <button onClick={() => navigate("/")}>Browse</button>
              <button onClick={() => navigate("/")}>Sell</button>
              <button onClick={() => navigate("/")}>Services</button>
              <button onClick={() => navigate("/help")}>Help</button>
              <button onClick={() => navigate("/")}>Community</button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
