import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import {
  ProductDetails,
  ClosedPage,
  Home,
  Help,
  Success,
  Cancel,
  EditProduct,
  Dashboard,
  Cart,
  AddProduct,
} from "./pages";

import "./App.css";

function App() {
  const isClosed = localStorage.getItem("closed");

  function closeSite() {
    if (isClosed === "true") {
      localStorage.setItem("closed", "false");
    } else {
      localStorage.setItem("closed", "true");
    }
    window.location.reload();
    // navigate("/");
  }

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={isClosed === "true" ? <ClosedPage /> : <Home />}
        />
        <Route
          path="/dashboard"
          element={<Dashboard closeSite={closeSite} />}
        />
        <Route
          path="/products/:id"
          element={isClosed === "true" ? <ClosedPage /> : <ProductDetails />}
        />
        <Route
          path="/cart"
          element={isClosed === "true" ? <ClosedPage /> : <Cart />}
        />
        <Route path="/add" element={<AddProduct />} />
        <Route path="/edit" element={<EditProduct />} />
        <Route
          path="/success"
          element={isClosed === "true" ? <ClosedPage /> : <Success />}
        />
        <Route
          path="/cancel"
          element={isClosed === "true" ? <ClosedPage /> : <Cancel />}
        />
        <Route
          path="/error"
          element={isClosed === "true" ? <ClosedPage /> : <Cancel />}
        />
        <Route
          path="/help"
          element={isClosed === "true" ? <ClosedPage /> : <Help />}
        />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
