import React, { createContext, useEffect, useState } from "react";
const CartContext = createContext(null);
const CartDispatchContext = createContext(null);
const SavedDataContext = createContext(null);

function CartProvider({ children }) {
  const [cartData, setCartData] = useState(
    JSON.parse(localStorage.getItem("cart"))
  );

  useEffect(() => {
    if (localStorage.getItem("cart") === null) {
      setCartData([]);
    }
  }, []);

  return (
    <CartContext.Provider value={cartData}>
      <CartDispatchContext.Provider value={setCartData}>
        {children}
      </CartDispatchContext.Provider>
    </CartContext.Provider>
  );
}

export { CartProvider, CartContext, CartDispatchContext, SavedDataContext };
