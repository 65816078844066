import React from "react";
import { Link } from "react-router-dom";
import { categoriesData } from "../../data/categories";
import Frame from "../../assets/frame1.gif";
import Messi from "../../assets/messi.png";

const Categories = () => {
  return (
    <div className="categories d-flex flex-column align-items-start">
      <div>
        <span className="px-3 px-md-5 py-2 py-md-3 bg-primary text-white fw-bold">
          categories
        </span>
      </div>
      <div>
        <img
          src={Messi}
          alt="person"
          width={200}
          height={250}
          className="mt-5 d-none d-md-block"
        />
      </div>
      <div className="d-flex justify-content-sm-center mt-3">
        <ul className="list-unstyled d-flex d-md-block w-100 gap-2 justify-content-start flex-wrap">
          {categoriesData.map((item) => {
            return (
              <li
                key={item.id}
                className="category-item d-flex gap-2 gap-md-3 text-sm"
              >
                <Link to="/">{item.text}</Link>
              </li>
            );
          })}
        </ul>
      </div>
      <div>
        <img
          src={Frame}
          alt="frame"
          title="person"
          style={{ objectFit: "contain" }}
          width="180"
          height="300"
          loading="lazy"
          className="d-none d-md-block"
        />
      </div>
    </div>
  );
};

export default Categories;
