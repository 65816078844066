import React from "react";
import Person from "../../assets/person.png";
import Frame1 from "../../assets/frame4.gif";
import Frame2 from "../../assets/frame2.gif";
import Frame3 from "../../assets/frame3.gif";

const Welcome = () => {
  const welcomeImgs = [
    { id: 1, src: Frame1, title: "one", height: 150, alt: "frame1" },
    { id: 2, src: Frame2, title: "two", height: 135, alt: "frame2" },
    { id: 3, src: Frame3, title: "three", height: 150, alt: "frame3" },
  ];
  return (
    <div className="welcome d-flex flex-column text-start">
      <div>
        <span className="px-3 px-md-5 py-2 py-md-3 bg-danger text-white fw-bold">
          welcoMe
        </span>
      </div>
      <div>
        <img
          src={Person}
          alt="person"
          width={200}
          height={250}
          className="mt-5"
          loading="lazy"
        />
      </div>

      <span className=" mt-3">
        FREE SHIPPING, IF YOU ORDER ENOUGH
        <br />
        NO REFUNDS, NO RETURNS, NO EXCHANGES, NO CANCELLATIONS
        <br />
        LOVE YOU!
      </span>
      <div className="d-flex align-items-center flex-wrap">
        {welcomeImgs.map((item) => {
          return (
            <img
              key={item.id}
              src={item.src}
              title={item.title}
              width="90"
              height={item.height}
              alt={item.alt}
              style={{ objectFit: "contain" }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Welcome;
