import { Link } from "react-router-dom";
import Navbar from "../components/navbar/Navbar";

const Success = () => {
  return (
    <>
      <Navbar />
      <div>
        <h1 className="text-success">Success</h1>
        <h2>Thank you for your purchase!</h2>
        <Link to={"/"} className="text-decoration-none fs-5">
          Go Home to continue shopping
        </Link>
      </div>
    </>
  );
};

export default Success;
