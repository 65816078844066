import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar/Navbar";
import Egypt from "../assets/egypt.png";

const Help = () => {
  return (
    <div className="mb-5">
      <Navbar />
      <h2>Contact us: </h2>
      <div
        className=" d-flex flex-column m-auto mt-5"
        style={{ width: "280px" }}
      >
        <Link
          to="mailto:pipiefinn@gmail.com"
          className="btn btn-success text-white mb-3"
        >
          Email: pipiefinn@gmail.com
        </Link>
        <Link to="tel:+32488244570" className="btn btn-success text-white">
          Mobile: +32488244570
        </Link>
        <Link
          className="btn btn-success text-white mt-3"
          to={
            "https://www.instagram.com/deeznootjes/?igshid=OGQ5ZDc2ODk2ZA==&utm_source=qr"
          }
          target="_blank"
        >
          IG: deeznootjes
        </Link>
      </div>

      <img
        src={Egypt}
        alt="egypt"
        className="d-block m-auto"
        width={450}
        height={200}
        loading="lazy"
        style={{ maxWidth: "100%", objectFit: "contain" }}
      />
    </div>
  );
};

export default Help;
