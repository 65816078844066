import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../data/BaseUrl";
import { toast } from "react-toastify";

import "./Products.css";

const Products = () => {
  const [productsData, setProductsData] = useState([]);

  useEffect(() => {
    toast.dismiss();
    toast.loading("loading products ...");
    axios.get(`${BASE_URL}api/Products/GetAllProducts`).then((res) => {
      if (res.data.success === true) {
        toast.dismiss();
        setProductsData(res.data.data.result);
      } else {
        toast.dismiss();
        toast.error(res.data.message);
      }
    });
  }, []);

  return (
    <div className="products d-flex flex-wrap gap-2 justify-content-center">
      {productsData?.length > 0 ? (
        productsData?.map((product) => {
          return (
            <div key={product.id} className="product position-relative">
              <Link to={`/products/${product.id}`}>
                {product?.sale !== "null" ||
                  (null && (
                    <span className="sale">SALE: {product?.sale}%</span>
                  ))}
                <img
                  src={`${BASE_URL}${product?.photo[0]?.filePath}`}
                  alt={product?.id}
                  className="product-img img-fluid position-relative"
                  style={{ height: "100%", objectFit: "cover" }}
                  width={230}
                  height={230}
                  loading="lazy"
                />
                <div className="product-title">
                  <span className="product-text">{product?.name}</span>
                </div>
              </Link>
            </div>
          );
        })
      ) : (
        <h4 className="text-muted">No Data to show</h4>
      )}
    </div>
  );
};

export default Products;
