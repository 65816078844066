import React from "react";
import Categories from "../categories/Categories";
import Welcome from "../welcome/Welcome";
import Products from "../products/Products";
import Egypt from "../../assets/egypt.png";

const Main = () => {
  return (
    <div className="main mb-0 my-md-5 ">
      <div className="row mb-4 flex-column flex-md-row">
        <div className=" col-12 col-md-3 text-start text-sm-center order-2 order-md-1">
          <Categories />
        </div>
        <div className="col-12 col-md-6 order-3 order-md-2">
          <Products />
        </div>
        <div className="col-12 col-md-3 mt-5 mt-sm-0 order-1 order-md-3 ">
          <Welcome />
        </div>
      </div>
      <img
        src={Egypt}
        alt="egypt"
        className="d-block m-auto"
        width={450}
        height={200}
        loading="lazy"
        style={{ maxWidth: "100%", objectFit: "contain" }}
      />
    </div>
  );
};

export default Main;
