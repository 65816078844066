import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../data/BaseUrl";
import { toast } from "react-toastify";

const AddProduct = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const MAX_COUNT = 3;

  const [size, setSize] = useState([]);
  const [addProductObj, setAddProductObj] = useState({
    name: "",
    description: "",
    price: "",
    quantityInStore: "",
    file: selectedImage,
    size: size,
    sale: "",
  });

  const styles = {
    preview: {
      marginTop: 50,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    image: { maxWidth: "100%", maxHeight: 320, objectFit: "contain" },
    delete: {
      cursor: "pointer",
      padding: 15,
      background: "#1c68a9",
      color: "white",
      border: "none",
      margin: "20px 0",
      display: "block",
      width: "100%",
    },
  };

  useEffect(() => {
    if (
      localStorage.getItem("LOGS_NO-REF_ORSEW") !==
      process.env.REACT_APP_NOP_KEY
    ) {
      navigate("/");
    }
  }, [navigate]);

  const handleUploadFiles = (files) => {
    const uploaded = [...selectedImage];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          setSelectedImage([]);
          return true;
        }
      }
    });
    if (!limitExceeded) setSelectedImage(uploaded);
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  // This function will be triggered when the "Remove This Image" button is clicked
  const removeSelectedImage = () => {
    setSelectedImage([]);
    setFileLimit(false);
  };

  const handleInputChange = (e) => {
    let myProduct = { ...addProductObj };
    myProduct[e.target.name] = e.target.value;
    setAddProductObj(myProduct);
  };

  function addProduct() {
    if (addProductObj.name === "") {
      toast.error("Name Field is required");
      return;
    }

    toast.dismiss();
    toast.loading("Please wait");

    const formData = new FormData();

    formData.append("name", addProductObj?.name);
    formData.append("price", +addProductObj?.price);
    formData.append("description", addProductObj?.description);
    formData.append("size", addProductObj?.size?.toString());
    formData.append("sale", addProductObj?.sale);
    formData.append("quantityInStore", +addProductObj?.quantityInStore);

    for (let i = 0; i < selectedImage.length; i++) {
      formData.append("file", selectedImage[i]);
    }

    axios({
      method: "post",
      url: `${BASE_URL}api/Products/CreateProducts`,
      data: formData,
      headers: {
        Accept: "multipart/form-data",
        "content-type": "multipart/form-data",
      },
    }).then((res) => {
      if (res.status === 201) {
        toast.dismiss();
        navigate("/dashboard");
      } else {
        toast.error(res.data?.errors?.name[0]);
      }
    });
  }

  const handleSizeChange = (e) => {
    setSize([].slice.call(e.target.selectedOptions).map((item) => item.value));

    const cloned = { ...addProductObj };
    cloned.size = [].slice
      .call(e.target.selectedOptions)
      .map((item) => item.value);

    setAddProductObj({ ...addProductObj, size: cloned?.size });
  };

  return (
    <div className="container">
      <div className="row my-5">
        <div className="col-12 mb-4">
          <button
            type="button"
            className="btn btn-success d-block"
            onClick={() => navigate("/dashboard")}
          >
            Back
          </button>
        </div>
        <div className="col-12">
          <div>
            <div className="text-start">
              <label htmlFor="formFileLg" className="form-label">
                Upload Product Image
              </label>
              <input
                className="form-control form-control-lg"
                id="formFileLg"
                type="file"
                multiple
                accept="image/*"
                onChange={handleFileEvent}
                disabled={fileLimit}
              />
            </div>
            {selectedImage.length > 0 && (
              <>
                <div style={styles.preview} className="row">
                  {selectedImage?.map((img, index) => {
                    return (
                      <div key={index} className="col-sm-4">
                        <img
                          src={URL.createObjectURL(img)}
                          style={styles.image}
                          alt="Thumb"
                          loading="lazy"
                        />
                      </div>
                    );
                  })}
                </div>
                <button onClick={removeSelectedImage} style={styles.delete}>
                  Remove All
                </button>
              </>
            )}
          </div>
        </div>
        <div className="col-12 mt-3 text-start">
          <div className="mb-3">
            <label htmlFor="exampleFormControlInput2" className="form-label">
              Name
            </label>
            <input
              type="text"
              required
              className="form-control"
              id="exampleFormControlInput2"
              name="name"
              onChange={handleInputChange}
              placeholder="enter name"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="exampleFormControlTextarea3" className="form-label">
              Description
            </label>
            <input
              type="text"
              className="form-control"
              name="description"
              id="exampleFormControlInput3"
              onChange={handleInputChange}
              placeholder="Description"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="exampleFormControlTextarea3" className="form-label">
              Quantity
            </label>
            <input
              type="number"
              className="form-control"
              name="quantityInStore"
              min="0"
              id="exampleFormControlTextarea3"
              onChange={handleInputChange}
              placeholder="Quantity"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="exampleFormControlTextarea4" className="form-label">
              Price
            </label>
            <input
              type="number"
              className="form-control"
              name="price"
              id="exampleFormControlTextarea4"
              onChange={handleInputChange}
              placeholder="Price"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="exampleFormControlTextarea5" className="form-label">
              Sale
            </label>
            <input
              type="text"
              className="form-control"
              name="sale"
              id="exampleFormControlTextarea5"
              onChange={handleInputChange}
              placeholder="Sale in percentage"
            />
          </div>

          <div className="mb-3">
            <label htmlFor="exampleFormControlTextarea6" className="form-label">
              Size
            </label>
            <select
              multiple
              className="form-select"
              aria-label="Default select example"
              value={size}
              onChange={handleSizeChange}
            >
              <option value="sm">sm</option>
              <option value="md">md</option>
              <option value="lg">lg</option>
              <option value="xl">xl</option>
              <option value="xxl">xxl</option>
            </select>
          </div>

          <button
            type="button"
            className="btn btn-success w-100 d-block"
            onClick={addProduct}
          >
            Add Product
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
